import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: Error): void {
    if (error?.message && error.message.includes('Failed to fetch dynamically imported module')) {
      console.warn('Dynamic import error:', error);
      window.location.reload();
    } else {
      throw error;
    }
  }
}
