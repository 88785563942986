import { Injectable, TemplateRef, inject } from '@angular/core';
import { VudToaster } from '@vismaux/ngx-vud';

import { ToastContent } from '#shared/components/toaster/toaster.model';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  readonly toaster = inject(VudToaster);
  private templates: { [key: string]: TemplateRef<unknown> } = {};
  private activeToasts = new Set<string>();
  private toastIdMap = new Map<string, number>();
  private nextToastId = 1;

  public setTemplate(
    type: 'success' | 'info' | 'warning' | 'danger',
    template: TemplateRef<unknown>,
  ) {
    this.templates[type] = template;
  }

  public showInfo(message: ToastContent, id: string | null, duration: number | null = 10000) {
    if (id && this.activeToasts.has(id)) {
      return;
    }

    this.showToast('info', duration, message, id);
  }

  public showError(message: ToastContent, id: string | null, duration: number | null = 30000) {
    if (id && this.activeToasts.has(id)) {
      return;
    }

    this.showToast('danger', duration, message, id);
  }

  public showSuccess(message: ToastContent, id: string | null, duration: number | null = 10000) {
    if (id && this.activeToasts.has(id)) {
      return;
    }

    this.showToast('success', duration, message, id);
  }

  public showWarning(message: ToastContent, id: string | null, duration: number | null = 30000) {
    if (id && this.activeToasts.has(id)) {
      return;
    }

    this.showToast('warning', duration, message, id);
  }

  public hide(id: string) {
    const numericId = this.toastIdMap.get(id);
    if (numericId === undefined) {
      return;
    }
    this.toaster.hide(numericId);
    this.activeToasts.delete(id);
    this.toastIdMap.delete(id);
  }

  private showToast(style, duration: number | null, message: ToastContent, id?: string) {
    const template = this.templates[style];
    if (!template) {
      throw new Error(`Toaster: Template for type "${style}" is not set.`);
    }

    const numericId = this.nextToastId++;
    this.toastIdMap.set(id, numericId);
    this.activeToasts.add(id);
    this.toaster.show({ template, context: { message } }, { style, duration });

    if (duration > 0) {
      setTimeout(() => {
        this.activeToasts.delete(id);
      }, duration);
    }
  }
}
