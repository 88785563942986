@use '@vismaux/vud/src/scss/settings/config' as conf;

.navbar-brand-menu {
  display: block;
  top: 0;
  width: auto;
  padding: 0;
  margin-top: 0;
  position: static;

  > li {
    overflow: visible;

    > a {
      border-top: conf.rem(1px) solid var(--navigation-default-dropdown-divider-color-strong);
      font: conf.$font-md;
      color: var(--navigation-default-dropdown-text-color);
      padding: 0 conf.rem(16px) 0 conf.rem(16px);
      min-height: conf.rem(64px);
      display: inline-flex;
      align-items: center;
      min-width: 30rem;

      img {
        margin-right: conf.rem(16px);
        height: conf.rem(48px);
        width: conf.rem(48px);
      }

      > span {
        display: block;
        width: auto;
        white-space: normal;
      }

      &.active {
        background-color: var(--module-container-active-bg-color);
        position: relative;
        overflow: visible;

        &::before {
          content: '';
          display: block;
          position: absolute;
          width: conf.rem(6px);
          height: 100%;
          left: 0;
          top: 0;
          background-color: var(--module-container-active-before-color);
        }
      }

      &:focus {
        box-shadow: none;
        outline: conf.rem(2px) solid var(--navigation-secondary-item-focus-border-color);
        outline-offset: conf.rem(-2px);
      }
    }

    .badge {
      position: absolute;
      right: conf.rem(26px);
      top: conf.rem(20px);
    }
  }
}
