import { Injectable, inject } from '@angular/core';
import { WebSocketClient } from '@data-access/bulk-operations-ws';

import { ToasterService } from '#shared/components/toaster/toaster.service';

@Injectable({
  providedIn: 'root',
})
export class WebSocketToasterService {
  private readonly wsMessages$ = inject(WebSocketClient).getDirectStream();
  private readonly toasterService = inject(ToasterService);

  constructor() {
    this.subscribeToWebSocket();
  }

  private subscribeToWebSocket() {
    this.wsMessages$.subscribe({
      error: () => {
        this.displayConnectionLostMessage();
      },
    });
  }

  private displayConnectionLostMessage() {
    this.toasterService.showError(
      $localize`Connection to the server was lost. Please refresh the page to reconnect.`,
      'connection-lost',
      null,
    );
  }
}
