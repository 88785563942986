import { timeStampNow, ConsoleApiName, ErrorSource, initConsoleObservable } from '@datadog/browser-core';
import { StatusType } from '../logger/isAuthorized';
import { createErrorFieldFromRawError } from '../createErrorFieldFromRawError';
export const LogStatusForApi = {
  [ConsoleApiName.log]: StatusType.info,
  [ConsoleApiName.debug]: StatusType.debug,
  [ConsoleApiName.info]: StatusType.info,
  [ConsoleApiName.warn]: StatusType.warn,
  [ConsoleApiName.error]: StatusType.error
};
export function startConsoleCollection(configuration, lifeCycle) {
  const consoleSubscription = initConsoleObservable(configuration.forwardConsoleLogs).subscribe(log => {
    var _a;
    const collectedData = {
      rawLogsEvent: {
        date: timeStampNow(),
        message: log.message,
        origin: ErrorSource.CONSOLE,
        error: log.error && createErrorFieldFromRawError(log.error),
        status: LogStatusForApi[log.api]
      },
      messageContext: (_a = log.error) === null || _a === void 0 ? void 0 : _a.context,
      domainContext: {
        handlingStack: log.handlingStack
      }
    };
    lifeCycle.notify(0 /* LifeCycleEventType.RAW_LOG_COLLECTED */, collectedData);
  });
  return {
    stop: () => {
      consoleSubscription.unsubscribe();
    }
  };
}
