export function buildCommonContext(globalContextManager, userContextManager, acccountContextManager) {
  return {
    view: {
      referrer: document.referrer,
      url: window.location.href
    },
    context: globalContextManager.getContext(),
    user: userContextManager.getContext(),
    account: acccountContextManager.getContext()
  };
}
