<div class="justify-content-between tw-flex tw-flex-col">
  <div>
    <div class="toast-transparent with-title">
      <span class="vismaicon vismaicon-filled vismaicon-sm vismaicon-error"></span>
      <span class="font-weight-bold" i18n
        >Please check details below and upload a valid file to continue with the import</span
      >
      <div class="errors-wrapper">
        @for (error of errorsList; track error) {
          <div class="errors-wrapper">
            <span>{{ error.message }}</span>
            @if (error.items) {
              <ol>
                @for (item of error.items; track item; let isLast = $last) {
                  <li>
                    {{ getItemName(item) }}
                    @if (!isLast) {
                      <span></span>
                    }
                  </li>
                }
              </ol>
            }
          </div>
        }
      </div>
    </div>
  </div>
</div>
