import {
  ContractSalaryTypeEnum,
  OperationStatusEnum,
  OperationTypeEnum,
  PositionEndReasonEnum,
  TaskStatusEnum,
  TypeOfPositionEnum,
} from '@data-access/bulk-operations-api';
import { EmployeeStatusEnum } from '@item-cache';

export class EnumLocalizationHelper {
  static getContractSalaryType(value: ContractSalaryTypeEnum): string {
    switch (value) {
      case ContractSalaryTypeEnum.Hourly:
        return $localize`Hourly`;
      case ContractSalaryTypeEnum.Yearly:
        return $localize`Yearly`;
      case ContractSalaryTypeEnum.Monthly:
        return $localize`Monthly`;
      default:
        if (!value) return '';
        console.warn(`There is no such contract salary type ${value}`);
        return value;
    }
  }

  static getEmployeeStatus(value: EmployeeStatusEnum): string {
    switch (value) {
      case EmployeeStatusEnum.Active:
        return $localize`Active`;
      case EmployeeStatusEnum.Inactive:
        return $localize`Inactive`;
      case EmployeeStatusEnum.Future:
        return $localize`Future`;
      case EmployeeStatusEnum.Draft:
        return $localize`Draft`;
      default:
        if (!value) return '';
        console.warn(`There is no such employee status ${value}`);
        return value;
    }
  }

  static getOperationStatus(status: OperationStatusEnum): string {
    switch (status) {
      case OperationStatusEnum.Successful:
        return $localize`Successful`;
      case OperationStatusEnum.Incomplete:
        return $localize`Incomplete`;
      case OperationStatusEnum.InProgress:
        return $localize`In Progress`;
      default:
        if (!status) return '';
        console.warn(`There is no such operation status ${status}`);
        return status;
    }
  }

  static getOperationType(value: OperationTypeEnum): string {
    switch (value) {
      case OperationTypeEnum.AbsenceRegistrationImport:
        return $localize`Absences`;
      case OperationTypeEnum.VacationBalanceImport:
        return $localize`Vacation balances`;
      case OperationTypeEnum.SalaryUpdateV2:
        return $localize`Salary update`;
      case OperationTypeEnum.EndFixedTransactions:
        return $localize`End fixed transactions`;
      case OperationTypeEnum.ChangeTimeAgreements:
        return $localize`Time agreements`;
      case OperationTypeEnum.PositionTagsUpdate:
        return $localize`Position tags update`;
      case OperationTypeEnum.EndPositions:
        return $localize`End positions`;
      case OperationTypeEnum.CostUnitValueUpsert:
        return $localize`Cost unit edit`;
      case OperationTypeEnum.AssignUser:
        return $localize`Create users`;
      case OperationTypeEnum.ChangeManager:
        return $localize`Change managers`;
      case OperationTypeEnum.EditFixedTransactions:
        return $localize`Edit fixed transactions`;
      default:
        if (!value) return '';
        console.warn(`There is no such operation type ${value}`);
        return value;
    }
  }

  static getPositionEndReason(value: PositionEndReasonEnum): string {
    switch (value) {
      case PositionEndReasonEnum.InvoluntaryTermination:
        return $localize`Involuntary termination`;
      case PositionEndReasonEnum.Resignation:
        return $localize`Resignation`;
      case PositionEndReasonEnum.HiringTermsExpiration:
        return $localize`Hiring terms expiration`;
      case PositionEndReasonEnum.DataEntryMistake:
        return $localize`Data entry mistake`;
      case PositionEndReasonEnum.Technical:
        return $localize`Technical`;
      case PositionEndReasonEnum.Organizational:
        return $localize`Organizational`;
      default:
        if (!value) return '';
        console.warn(`There is no such position end reason ${value}`);
        return value;
    }
  }

  static getTaskStatus(status: TaskStatusEnum): string {
    switch (status) {
      case TaskStatusEnum.Successful:
        return $localize`Successful`;
      case TaskStatusEnum.Created:
        return $localize`Created`;
      case TaskStatusEnum.Failed:
        return $localize`Failed`;
      case TaskStatusEnum.Waiting:
        return $localize`Waiting`;
      case TaskStatusEnum.PollingTimeout:
        return $localize`Polling timeout`;
      case TaskStatusEnum.Polling:
        return $localize`Polling`;
      default:
        if (!status) return '';
        console.warn(`There is no such task status ${status}`);
        return status;
    }
  }

  static getTypeOfPosition(value: TypeOfPositionEnum): string {
    switch (value) {
      case TypeOfPositionEnum.Ordinary:
        return $localize`Ordinary contract`;
      case TypeOfPositionEnum.Pension:
        return $localize`Pension and other remunerations without employment`;
      case TypeOfPositionEnum.Freelancer:
        return $localize`Freelancer, Contractors, Only receiving honorarium`;
      case TypeOfPositionEnum.Maritime:
        return $localize`Maritime employment`;
      default:
        if (!value) return '';
        console.warn(`There is no such type of position ${value}`);
        return value;
    }
  }
}
