<div class="panel p-relative">
  <div class="mh-160 cursor-pointer" [ngClass]="{ disabled: isCardDisabled }" (click)="clickCard.emit()">
    <div class="panel-body w-100 align-items-center justify-content-between p-relative tw-flex">
      <div class="mr-12">
        <div class="align-items-center mb-12 tw-flex">
          <span class="icon-40 d-print-block bg-size-cover mr-12" role="img" [ngClass]="iconClass"></span>
          <h3 class="m-0"><ng-content select="di-grid-card-title" /></h3>
        </div>
        <div class="text-truncate-2">
          <ng-content select="di-grid-card-desc" />
        </div>
      </div>
      @if (!isExternalLink && !isCardDisabled) {
        <span class="grid-next-icon icon-16" role="button"></span>
      }
      @if (isExternalLink && !isCardDisabled) {
        <span class="vismaicon vismaicon-export vismaicon-sm p-absolute right-12 top-12" role="button"></span>
      }
    </div>
  </div>
  @if (isCardDisabled) {
    <span
      class="vismaicon vismaicon-filled vismaicon-sm vismaicon-info p-absolute right-12 top-12"
      data-testid="info-icon"
      [vudTooltip]="functionDisabledTooltip"
    ></span>
  }
</div>
<ng-template #functionDisabledTooltip>
  <span i18n>Sorry, this function is temporarily disabled. Please try again later.</span>
</ng-template>
